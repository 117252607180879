import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import SectionTitle from 'UI/components/SectionTitle';
import ContentfulParser from 'components/BlogCommon/Article/ContentfulParser';
import Media from 'UI/components/Media';
import {
  selectIsDesktop,
  selectIsMobile,
  selectIsSmallTablet,
  selectIsTablet,
} from 'redux/selectors/layout';
import { getDocumentFields } from 'utils/helper';
import styles from './styles.module.scss';

const ScrollingTabs = ({ section, type }) => {
  const itemRefs = useRef([]);
  const stepperRefs = useRef([]);
  const [activeIndex, setIndex] = useState(0);
  const [overlayStyle, setOverlayStyle] = useState({});

  const isDesktop = useSelector(selectIsDesktop);
  const isTablet = useSelector(selectIsTablet);
  const isSmallTablet = useSelector(selectIsSmallTablet);
  const isMobile = useSelector(selectIsMobile);

  const {
    title,
    subtitle,
    contentModules,
  } = useMemo(() => getDocumentFields(
    section,
    [
      'title',
      'subtitle',
      'contentModules',
    ],
  ), [section]);

  const modules = useMemo(() => contentModules?.map((module) => {
    const { title: itemTitle, images, text } = getDocumentFields(module, [
      'title',
      'images',
      'text',
    ]);

    return {
      title: itemTitle,
      image: images?.[0],
      text,
    };
  }), [contentModules]);

  const handleClick = (index) => {
    if (itemRefs.current[index]) {
      itemRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const rectLimitTop = isTablet ? 420 : 220;
  const rectLimitBottom = isTablet ? 380 : 180;

  useEffect(() => {
    const isInViewport = (element) => {
      const elementRect = element?.getBoundingClientRect();

      return (
        elementRect.top <= rectLimitTop
        && elementRect.bottom >= rectLimitBottom
      );
    };

    const handleScroll = () => {
      const firstVisibleIndex = itemRefs.current.findIndex(
        (ref) => ref && isInViewport(ref),
      );

      if (firstVisibleIndex !== -1) setIndex(firstVisibleIndex);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [
    rectLimitTop,
    rectLimitBottom,
  ]);

  useEffect(() => {
    if (stepperRefs.current[activeIndex]) {
      const ref = stepperRefs.current[activeIndex];

      if (isDesktop || isMobile) {
        setOverlayStyle({
          top: ref.offsetTop,
        });
      }

      if (isTablet || isSmallTablet) {
        setOverlayStyle({
          width: ref.offsetWidth,
          left: ref.offsetLeft,
        });
      }
    }
  }, [activeIndex]);

  return (
    <section className={styles.section}>
      <SectionTitle
        title={title}
        subtitle={subtitle}
        className={styles['section-title']}
      />
      <div className={styles.container}>
        <div className={styles.left}>
          <ul className={styles.stepper}>
            {(modules || []).map((item, i) => (
              <li
                key={i}
                ref={(el) => (stepperRefs.current[i] = el)}
                className={cn(styles.step, { [styles.active]: activeIndex === i })}
                onClick={() => handleClick(i)}
              >
                <span>
                  {i + 1}
                </span>
                {item.title}
              </li>
            ))}
            <span
              className={styles.overlay}
              style={overlayStyle}
            />
          </ul>
        </div>
        <div className={styles.right}>
          {(modules || []).map((item, i) => (
            <article
              key={i}
              ref={(el) => (itemRefs.current[i] = el)}
              className={styles.item}
            >
              <div className={styles['item-left']}>
                {item?.image && (
                  <Media
                    asset={item.image}
                    className={styles['item-image']}
                  />
                )}
              </div>
              <div className={styles['item-right']}>
                {item?.title && (
                  <h4 className={styles['item-title']}>{item.title}</h4>
                )}
                {item?.text && (
                  <div className={styles['item-text']}>
                    <ContentfulParser document={item.text} />
                  </div>
                )}
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ScrollingTabs;
